$(document).ready(function(){
    $.fn.numberWow = function(delay, delayStart, arg) {
		var delay =  delay || 0;
		var delayStart =  delayStart || 0;

		function numberWithSpaces(x) {
			var parts = x.toString().split(".");
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
			return parts.join(".");
		}

    	$(this).each(function () {
    		var obj = $(this)[0];
			var number =  arg.number || parseInt($(this).html().replace(/\s/g,''));
			var anim = arg.anim || 0;

			positiveCounter = function(obj, number, anim) {
				return function() {
					obj.innerHTML = numberWithSpaces(anim);
					if(anim > 10 && anim + 10 < number) {
					    anim += 10;
					} 
					if(number > 100 && anim + 100 < number && number > 1000) {
						anim += 100;
					}
					if(number > 1000 && anim + 1000 < number && number > 10000) {
						anim += 1000;
					}
					if(number > 10000 && anim + 10000 < number && number > 100000) {
						anim += 10000;
					}
					else {
					    anim += 1;
					}
					if(anim <= number) {
					    numberAnim(obj, number, anim);
					}
	            }
			}

			negativeCounter = function(obj, number, anim) {
				return function() {
					obj.innerHTML = numberWithSpaces(anim);
					if(anim > 10 && anim - 10 > number) {
					    anim -= 10;
					} 
					if(number > 100 && anim - 100 > number && number > 1000) {
						anim -= 100;
					}
					else {
					    anim -= 1;
					}
					if(anim >= number) {
					    numberAnim(obj, number, anim);
					}
	            }
			}

			numberAnim = function(obj, number, anim) {
				var multiplier = number - anim > 0;
	
				if(multiplier) {
		      		setTimeout(positiveCounter(obj, number, anim), delay);
				} else {
					setTimeout(negativeCounter(obj, number, anim), delay);
				}
			};

		    setTimeout(function() { numberAnim(obj, number, anim) }, delayStart);
		});
    };

});