 "use strict";

$(document).ready(function(){
    $.fn.fadeBlock = function(obj) {
        var className = obj.className || 'am-fadeOut';
        var xHeight = obj.xHeight || 0.8;
        var xtHeight = obj.xtHeight || 0.8;
        var topScroll = obj.topScroll || false;

        var justExecuted = false;

        var windowHeight = $(window).height();
        var blocks = this;

        var bo = $(window).scrollTop() + windowHeight * xHeight;
        blocks.each(function (index, value) {
            var height = blocks.eq(index).offset().top;
            if(height < $(window).scrollTop()) {
                blocks.eq(index).addClass(className);
            }
        });

        var fadeBlock = function() {
            blocks.each(function (index, value) {
                var bo = $(window).scrollTop() + windowHeight * xHeight;
                var height = blocks.eq(index).offset().top;
                var scroll = $(window).scrollTop();

                if(bo >= height && height > scroll) {
                    blocks.eq(index).removeClass(className);   
                } else if (height - windowHeight > scroll) {
                    blocks.eq(index).addClass(className);   
                    blocks.eq(index).removeClass('am-top');
                } else if(topScroll) {
                    if (height + $(this).outerHeight() < scroll) {
                        blocks.eq(index).addClass(className + '--top');
                        blocks.eq(index).addClass('am-top');
                    } else if (blocks.eq(index).hasClass(className + '--top')
                        && (height + $(this).outerHeight()) - (windowHeight * xtHeight)
                            > scroll) {
                        blocks.eq(index).removeClass(className + '--top');
                    }
                }
            });
        };
        $(document).ready(fadeBlock);
        $(document).scroll(fadeBlock);
    };
});